module.exports = {
  main: [
    // {
    //   name: `Home`,
    //   url: `/`,
    // },
    {
      name: `Startups`,
      url: `/startups`,
    },
    {
      name: `Partners`,
      url: `/partners`,
    },
    // {
    //   name: `Agenda`,
    //   url: `/agenda`,
    // },
    {
      name: `People`,
      url: `/people/all`,
    },
    {
      name: `Speaker`,
      url: `/people/speakers`,
    },
    {
      name: `Archive`,
      url: `/archive`,
    },
  ],
  mobile: {
    main: [
      // {
      //     name: 'Home',
      //     url: '/',
      //     highlight: false,
      //     type: 'Link',
      // },
      {
        name: "Rearview",
        url: "/rearview",
        highlight: false,
        type: "Link",
      },
      // {
      //   name: "Agenda",
      //   url: "/agenda",
      //   highlight: false,
      //   type: "Link",
      // },
      {
        name: "People",
        url: "/people/all",
        highlight: false,
        type: "Link",
      },
      {
        name: "Startups",
        url: "/startups",
        highlight: false,
        type: "Link",
      },
      {
        name: "Partners",
        url: "/partners",
        highlight: false,
        type: "Link",
      },
      // {
      //   name: "Archive",
      //   url: "/archive",
      //   highlight: false,
      //   type: "Link",
      // },
    ],
    drawer: [],
  },
  footer: [
    {
      title: "EXPO2022",
      items: [
        {
          name: "Home",
          url: "/",
        },
        // {
        //   name: "Agenda",
        //   url: "/agenda/day3",
        // },
        {
          name: "EXPO Startups",
          url: "/startups",
        },
        {
          name: "About the People",
          url: "/people/all",
        },
        {
          name: "About the Partners",
          url: "/partners",
        },
        // {
        //   name: "Press Release",
        //   url: "/#pressrelease",
        // },
        // {
        //   name: "Register now",
        //   url: "/register",
        // },
        // {
        //   name: "Livestream",
        //   url: "/live",
        // },
        // {
        //   name: "All-Access Area",
        //   url: "/inside/login",
        // },
        // {
        //   name: "Rearview",
        //   url: "/rearview",
        // },
        {
          name: "Archive",
          url: "/archive",
        },
      ],
    },
    {
      title: "STARTUP AUTOBAHN",
      items: [
        {
          name: "About the Platform",
          url: "https://startup-autobahn.com",
        },
        {
          name: "Career",
          url: "https://plugandplaytechcenter.bamboohr.com/jobs/",
        },
        {
          name: "Premaster",
          url: "https://premaster.pnptc.career/",
        },
        {
          name: "Newsroom | Blog",
          url: "https://startup-autobahn.com/press/",
        },
        {
          name: "Image Database",
          url: "https://pictures.startup-autobahn.com/",
        },
      ],
    },
    {
      title: "Internal",
      items: [
        {
          name: "Contact Us",
          url: "mailto:hannah@pnptc.com",
        },
        {
          name: "Imprint",
          url: "/imprint",
        },
        {
          name: "Privacy Policy & Legal",
          url: "/privacy-policy",
        },
        {
          name: "Sitemap",
          url: "/sitemap-index.xml",
        },
        // {
        //   name: "Humans.txt",
        //   url: "/humans.txt",
        // },
      ],
    },
  ],
}
